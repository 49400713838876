// 融资租赁
<template>
  <div class="financial-lease">
    <section>
      <Info class="trust-title" />
    </section>
    <section>
      <div class="trust-title">
        <h3>服务案例</h3>
      </div>
      <el-row class="lease-list" :gutter="20">
        <el-col class="lease-item" :span="6" v-for="(item,index) in data" :key="index">
          <el-image :src="$base+item.logo"></el-image>
          <p class="lease-item-title">{{item.name}}</p>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import Info from '@/components/Info'
import { getCase } from '@/api'
export default {

  components: {
    Info
  },
  data () {
    return {
      data: []
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getCase('fwal')
      this.data = data
    }
  },

};
</script>

<style scoped lang="less">
section {
  margin-bottom: 67px;
}
/deep/ .trust-title {
  margin-bottom: 27px;
  margin-left: 30px;
  h3 {
    font-size: 24px;
    margin-bottom: 12px;
  }
}
.lease-list {
  text-align: center;
  margin-top: 50px;
  .lease-item {
    margin-bottom: 27px;
    .lease-item-title {
      margin-top: 16px;
    }
  }
}
@media (max-width: 1299px) {
  /deep/ .trust-title {
    img {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
